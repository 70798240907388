import React from "react";
import { Box, Typography } from "@mui/material";
import ContainerPage from "../../components/modules/containerPage";
import { MAIN_ROUTE } from "../../types/const";

const TermsPage = (): JSX.Element => {
  const breadcrumbs = [
    {
      id: 0,
      title: "home",
      link: MAIN_ROUTE,
    },
    {
      id: 1,
      title: "terms_and_conditions",
      link: "",
    },
  ];

  return (
    <ContainerPage name="terms_and_conditions" breadcrumbs={breadcrumbs}>
      <div className="about-page__content d-flex justify-center">
        <Box mt={2} mb={5} className="terms">
          <Typography fontSize={20} fontWeight={600} mb={2}>
            УМОВИ ВИКОРИСТАННЯ САЙТУ
          </Typography>

          <Box ml={2}>
            <Typography fontSize={16} fontWeight={400} mb={1} paragraph={true}>
              УВАГА! Перед переглядом цього веб-сайту уважно прочитайте ці
              умови. Відкриваючи веб-сайт https://www.orientalcaffe.com.ua/ , ви
              зобов'язуєтесь дотримуватись викладених далі положень та умов.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Якщо Ви не згодні з цими умовами, не використовуйте цей веб-сайт.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Даний сайт представлений фізичною особою часним підприємцем (далі
              за текстом – Компанія). Інформація, що розміщена на даному сайті є
              інтелектуальною власністю, що захищена згідно норм чинного
              законодавства України.
            </Typography>
          </Box>

          <Typography
            variant="subtitle1"
            fontWeight={500}
            mb={1}
            paragraph={true}
          >
            Використання сайту
          </Typography>

          <Box ml={2}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Сайт https://www.orientalcaffe.com.ua/ (далі за текстом – сайт)
              дозволяє Вам переглядати та завантажувати матеріали цього сайту
              виключно в інформаційних цілях, для особистого некомерційного
              використання, за умови збереження Вами всієї інформації про
              авторське право та інших відомостей про право власності, що
              містяться у вихідних матеріалах і будь-яких їх копіях.
              Забороняється змінювати матеріали цього Сайту, а також поширювати
              або демонструвати їх у будь-якому вигляді або використовувати їх
              будь-яким іншим чином для суспільних чи комерційних цілей.
              Будь-яке використання цих матеріалів на інших сайтах або в
              комп'ютерних мережах заборонено.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Відкриваючи будь-яку сторінку цього сайту, ви погоджуєтесь
              дотримуватись цих Умов використання сайту (далі за текстом –
              Умови).
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Дія цих Умов поширюється як на фізичних, так і на юридичних осіб.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Звернення, пропозиції та претензії фізичних та юридичних осіб до
              Компанії у зв'язку з цими Умовами, усіма питаннями щодо
              функціонування сайту, пов'язаними з правами та інтересами третіх
              осіб при його використанні можуть бути надіслані на електронну
              адресу contacts@orientalcaffe.com.ua .
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Право на використання фірмової назви, товарних знаків, доменних
              імен та ін. може бути надано виключно за письмовою угодою з
              Компанією.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Ці Умови можуть бути змінені та/або доповнені Компанією в
              односторонньому порядку без будь-якого спеціального повідомлення.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Редакція Умов, що діє, розташовується в мережі Інтернет за
              адресою: https://www.orientalcaffe.com.ua/terms-and-conditions .
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Компанія рекомендує користувачам регулярно перевіряти ці Умови на
              предмет їх зміни та/або доповнення. Продовження використання сайту
              користувачем після внесення змін та/або доповнень до цих Умов
              означає прийняття та згоду користувача з такими змінами та/або
              доповненнями.
            </Typography>
          </Box>

          <Typography
            variant="subtitle1"
            fontWeight={500}
            mb={1}
            paragraph={true}
          >
            Відмова від відповідальності
          </Typography>

          <Box ml={2}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Матеріали та послуги цього сайту надаються без жодних гарантій.
              Компанія не гарантує точності та повноти матеріалів, програм і
              послуг, що надаються на цьому сайті.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Інформація, яка опублікована на сайті у вигляді новин, статей,
              опису продуктів та послуг та інших інформаційних матеріалів
              Компанії, є актуальною на дату їх складання. В подальшому або у
              зв'язку з наступними подіями якась частина цієї інформації може
              виявитися не повною або не актуальною. Після публікації на сайті
              Компанія не гарантує оперативних коригувань такої інформації.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Компанія докладатиме всіх зусиль, щоб забезпечити точність і
              актуальність даних, що містяться на цьому сайті. У представленій
              на цьому сайті інформації можуть зустрітися технічні неточності
              або друкарські помилки. Цим нагадується, що інформація, наявна на
              цьому веб-сайті, може містити неточності та упущення, зокрема
              через дії третіх осіб.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Думка авторів статей, новин, коментарів та відгуків, які розміщені
              на сайті, може не співпадати з думкою та позицією Компанії.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Компанія за жодних обставин не несе відповідальності за будь-які
              збитки (включно з, але не обмежуючись збитком від втрати прибутку,
              даних або від переривання ділової активності), що виникли
              внаслідок використання, неможливості використання або результатів
              використання цього сайту.
            </Typography>
          </Box>

          <Typography
            variant="subtitle1"
            fontWeight={500}
            mb={1}
            paragraph={true}
          >
            Функціональне призначення сайту
          </Typography>

          <Box ml={2}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Товари і послуги, представлені на цьому сайті, пропонуються для
              ознайомлення, вибору та надають загальне уявлення про асортимент
              продукції та послуг, які представлені Компанією.
            </Typography>
          </Box>

          <Typography
            variant="subtitle1"
            fontWeight={500}
            mb={1}
            paragraph={true}
          >
            Взаємодія сайту з іншими ресурсами
          </Typography>

          <Box ml={2}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              При використанні користувачем сервісів, на сторінках сайту можуть
              бути присутніми коди інших інтернет-ресурсів і третіх осіб, в
              результаті чого такі інтернет ресурси і треті особи отримують Ваші
              дані. Отже, ці інтернет-ресурси можуть отримувати і обробляти
              інформацію, про те, що Ви відвідали ці сторінки, а також іншу
              інформацію, яку передає браузер користувача.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Використання зазначених сервісів необхідно Компанії для
              оперативного аналізу відвідувань сайту, внутрішньої і зовнішньої
              оцінки відвідуваності сайту, глибини переглядів, активності
              користувачів. Дані, отримані від зазначених сервісів Компанія не
              зберігає і не обробляє. Відповідно, якщо користувач в силу
              будь-яких причин не бажає, щоб зазначені сервіси отримували доступ
              до його персональних даних, користувач може за власним бажанням
              вийти зі свого аккаунта чи профіля, очистити файли cookie (через
              свій браузер).
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              На Сайті можуть бути розташовані посилання на інші веб-сайти
              (виключно в інформаційних цілях). При переході за посиланням на
              інші веб-сайти дія цих Умов на такі сайти поширюватися не буде. У
              зв'язку з чим, Компанія рекомендує переглядати політику в сфері
              конфіденційності і персональних даних кожного веб- сайту перед
              тим, як передавати будь-які персональні дані, за якими Вас можуть
              ідентифікувати.
            </Typography>
          </Box>

          <Typography
            variant="subtitle1"
            fontWeight={500}
            mb={1}
            paragraph={true}
          >
            Захист авторських прав
          </Typography>

          <Box ml={2} mb={3}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Всі права на інформацію, розміщену на даному сайті, належать їх
              власникам та захищені згідно норм чинного законодавства України.
              Зміна матеріалів, використання їх за іншим призначенням,
              несанкціоноване копіювання, поширення тощо є порушенням авторських
              прав. У разі порушення цих Умов Компанія залишає за собою право
              застосувати до порушників всі допустимі та визначенні чинним
              законодавством України заходи захисту своїх порушених прав.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Сторінки цього сайту можуть міститися застереження про права
              інтелектуальної власності та інформацію про авторські права, які
              повинні бути враховані і дотримані користувачем. Будь-яке
              використання інформації, розміщеної на цьому сайті, цілком або
              частково (відтворення, адаптація, переклад, розміщення, обробка
              вмісту та ін.) дозволяється лише за умови попередньої письмової
              згоди її власника.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Знаки для товарів та послуг (торговельні марки), що розміщені на
              сторінках даного сайту, в тому числі продукція, товари що
              виготовляється під такими торговельними марками, є зареєстрованими
              торговельними марками, що належать відповідним власникам. Будь-яке
              офіційне або неофіційне використання даних торговельних марок без
              згоди їх власників заборонене і є порушенням чинного законодавства
              України, в тому числі але не обмежуючись законодавства про
              авторські права, права інтелектуальної власності або законодавства
              про недобросовісну конкуренцію.
            </Typography>
          </Box>

          <Typography
            variant="subtitle1"
            fontWeight={500}
            mb={1}
            paragraph={true}
          >
            Реєстрація на сайті та видалення аккаунта
          </Typography>

          <Box ml={2}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Реєструючись на сайті, Ви погоджуєтеся надати достовірну та точну
              інформацію про себе і свої контактні дані.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Після реєстрації на сайті Ви отримуєте логін і пароль, за безпеку
              яких Ви несете відповідальність. Ви також несете відповідальність
              за всі дії під вашим логіном і паролем на сайті. У разі втрати
              реєстраційних даних ви зобов'язуєтеся повідомити нам про це.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              При видаленні акаунту, ми призупиняємо обробку Ваших персональних
              даних, які обробляли на основі Вашої згоди з Умовами та Політикою
              конфіденційності.
            </Typography>
          </Box>

          <Typography
            variant="subtitle1"
            fontWeight={500}
            mb={1}
            paragraph={true}
          >
            Зворотний зв'язок і коментарі
          </Typography>

          <Box ml={2}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Звертаючись до нас або залишаючи коментарі на сайті, Ви несете
              відповідальність, що Ваше повідомлення не є таким:
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              - що порушує законодавство України, містить загрози і образи,
              дискредитує інших користувачів, порушує права громадян приватне
              життя чи громадський порядок, носить непристойний, образливий
              характер, порушує загальноприйняті правила доброго тону та ін.;
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              - що розголошує відомості, що становлять державну або іншу
              спеціально охоронювану законом таємницю, містить публічні заклики
              до здійснення терористичної діяльності або публічно виправдовує
              тероризм, інші екстремістські матеріали, а також матеріали, що
              пропагує порнографію, культ насильства та жорстокості;
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              - що порушує тою чи іншою мірою честь і гідність, ділову
              репутацію;
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              - що сприяє або містить заклики до розпалювання релігійної,
              расової або міжнаціональної ворожнечі, або заклики до насильства;
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              - що містить недостовірну інформацію;
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              - що містить рекламу будь-яких товарів, послуг та/або робіт, у
              тому числі рекламні матеріали, комерційні пропозиції, адресовані
              невизначеному колу осіб, посилання на об'єкт рекламування та
              інформацію рекламного характеру;
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              - що містить охоронювані законодавством про інтелектуальну
              власність матеріали (у тому числі, авторським правом,
              законодавством про товарні знаки та ін.), без належного отримання
              дозволу власника прав на матеріал, що охороняється. При цьому
              тягар доведення того, що розміщення користувачем матеріалів на
              сайті не порушує авторські, суміжні та інші права третіх осіб,
              лежить на користувачі.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              - інші матеріали, які спонукають інших осіб на протиправну
              поведінку, що тягне за собою кримінальну, цивільно-правову та іншу
              відповідальність або якимось чином порушують положення чинного
              законодавства.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mt={2}
              mb={1}
              paragraph={true}
            >
              Користувач несе особисту відповідальність за будь-який контент
              та/або іншу інформацію, які він публікує на сайті або за його
              допомогою, а також за використання контенту та/або будь-якої
              інформації з сайту.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Компанія залишає за собою право видаляти будь-які матеріали,
              розміщені користувачами на сайті, якщо існує достатньо підстав
              вважати, що такі матеріали підпадають під заборонене в цьому
              розділі або іншим чином не відповідають йому. Незважаючи на
              вищевикладене, Компанія жодним чином не контролює вміст сайту, що
              надається іншими користувачами, не перевіряє цей вміст на його
              достовірність, доцільність або відповідність принципам, викладеним
              у цих Умовах.
            </Typography>
          </Box>

          <Typography
            variant="subtitle1"
            fontWeight={500}
            mb={1}
            paragraph={true}
          >
            Повідомлення та інформаційні розсилки
          </Typography>

          <Box ml={2}>
            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Компанія може направляти вам повідомлення у відповідь на Ваші
              запити та скарги, направлені через форму зворотного зв’язку та в
              процесі замовлення товарів та/або послуг, при управлінні
              замовленнями, видачі товарів, наданні послуг. Компанія надсилає
              повідомлення у вигляді e-mail-листів, SMS-повідомлень, сповіщень у
              веб-браузері.
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              mb={1}
              paragraph={true}
            >
              Компанія також може направляти вам інформаційні та маркетингові
              розсилки (новини, акції компанії, інформацію про акції, промокоди
              та знижки, персональні рекомендації, персональні знижки та
              пропозиції), які містять інформацію щодо товарів та/або послуг,
              рекламні та комерційні пропозиції щодо таких товарів та/або
              послуг. Інформаційні та маркетингові розсилки здійснюються на
              основі підписки на розсилки, оформленої Вами через сайт або при
              замовленні товарів та/або послуг через сайт. Компанія здійснює
              інформаційну та маркетингову розсилку у вигляді e-mail-листів,
              SMS-повідомлень, сповіщень у веб-браузері.
            </Typography>
          </Box>
        </Box>
      </div>
    </ContainerPage>
  );
};

export default TermsPage;
