import { ProductCardInterface } from "../interfaces/appointments";

export const topProducts = [
  {
    id: 1,
    category: "coffee",
    type_of_coffee: "В зернах",
    sort: "Arabica 80% / Robusta 20%",
    name: "il Rosso",
    state: "are_available",
    weight: 1,
    weight_box: 12,
    cost: 17.5,
    desc: "Купаж il ROSSO : 80% елітна Arabica з Центральної і Південної Америки і суміш високоякісної відбірної Robusta з найкращих областей Індії для найкращого італійського еспрессо. Дуже яскравий сорт, який сподобається любителям міцної кави з кислинкою. Особливий італійський метод обсмажування зерен надає каві стійкий післясмак квітів і сухофруктів.",
    info: "Купаж il ROSSO : 80% елітна Arabica з Центральної і Південної Америки і суміш високоякісної відбірної Robusta з найкращих областей Індії для найкращого італійського еспрессо. Дуже яскравий сорт, який сподобається любителям міцної кави з кислинкою. Особливий італійський метод обсмажування зерен надає каві стійкий післясмак квітів і сухофруктів. Період збору з грудня по березень. Культури знаходяться в тіні дерев Інга. Класифікація SHG виражається в строго високому вирощенні, що визначає високоякісну оброблену каву, вирощену на високій землі.",
    imgUrl: "/catalog/rosso.jpg",
    link: "",
  },
  {
    id: 2,
    category: "coffee",
    type_of_coffee: "В зернах",
    sort: "Arabica 100%",
    name: "Ashoka",
    state: "are_available",
    weight: 1,
    weight_box: 12,
    cost: 20,
    desc: "Купаж ASHOKA: 100% елітна Arabica, вирощена в Південній Америці. Суміш зерен з кращих областей Бразилії і Карибського моря для справжніх цінителів якісної кави, який має характерний післясмак з нотками мигдалю, арахісу і солодких фруктів.",
    info: 'Зростання кави відбувається на повному сонці - період збору врожаю з вересня по січень і з квітня по червень. Плантація знаходиться у відділі CACUA, де працює кооператив місцевих фермерів "Asociaciòn Mujeres Cafeteras". Класифікація SHG виражається в строго високому вирощенні, що визначає високоякісну оброблену каву, вирощену на високій землі. Кава виростає на околицях району Гірі, навколо гір та пагорбів діапазону Бабабудан. Вона збирається вручну та обробляється традиційним методом.',
    imgUrl: "/catalog/ashoka.jpg",
    link: "",
  },
  {
    id: 3,
    category: "coffee",
    type_of_coffee: "В зернах",
    sort: "Arabica 60% / Robusta 40%",
    name: "il Blu",
    state: "are_available",
    weight: 1,
    weight_box: 12,
    cost: 15.9,
    desc: "Купаж il BLU: 60% Арабіка з Центральної і Південної Америки і суміш високоякісної відбірної Робусти з найкращих областей Індії для найкращого італійського еспрессо. Особливий італійський метод обсмажування зерен надає каві аромат зі стійким післясмаком фундука і шоколаду.",
    info: "Вирощування в тіні - період збору з грудня по січень. Кава виростає на околицях району Гірі, навколо гір та пагорбів діапазону Бабабудан. Вона збирається вручну та обробляється традиційним методом, що миється.",
    imgUrl: "/catalog/blue.jpg",
    link: "",
  },
  {
    id: 4,
    category: "coffee",
    type_of_coffee: "В зернах",
    sort: "Arabica 30% / Robusta 70%",
    name: "il Nero",
    state: "are_available",
    weight: 1,
    weight_box: 12,
    cost: 16,
    desc: "NERO – насичена кава. Бальзамічна кислинка поєднується з переливним ароматом какао, який переходить у смак лісових горіхів і смаженого зерна. Його тривалий післясмак несе нотки карамелі та підсмаженого хліба.",
    info: "NERO — це насичена кава, яка має сильний аромат какао-порошку та підсмажених зерен завдяки поєднанню дорогоцінної робусти та південноамериканської арабіки.",
    imgUrl: "/catalog/nero.jpg",
    link: "",
  },
  {
    id: 5,
    category: "coffee",
    type_of_coffee: "В зернах",
    sort: "Arabica 30% / Robusta 70%",
    name: "Rococo",
    state: "are_available",
    weight: 1,
    weight_box: 12,
    cost: 13.7,
    desc: "Купаж кави Rococo: 30% Арабіки з Бразилії і 70% суміш з високоякісної відбірної Робусти з Індії і Африки для любителів італійського еспрессо. Класична суміш, яка сподобається любителям міцного еспрессо. Особливе італійське обсмажування зерен надає характерну міцність каві і нотки молочного шоколаду з карамельним післясмаком.",
    info: "Купаж кави Rococo: 30% Арабіки з Бразилії і 70% суміш з високоякісної відбірної Робусти з Індії і Африки для любителів італійського еспрессо. Класична суміш, яка сподобається любителям міцного еспрессо. Особливе італійське обсмажування зерен надає характерну міцність каві і нотки молочного шоколаду з карамельним післясмаком.",
    imgUrl: "/catalog/rococo.jpg",
    link: "",
  },
  {
    id: 6,
    category: "coffee",
    type_of_coffee: "В зернах",
    sort: "Arabica 30% / Robusta 70%",
    name: "Le Percentuale Vellutato",
    state: "are_available",
    weight: 1,
    weight_box: 12,
    cost: 16.4,
    desc: "Середнє і оксамитове тіло. Середня кислинка супроводжується квітковими нотками аромату. Вміст гіркоти дає простір для високого відчуття солодкості, характерного для стиглих фруктів і шоколаду. В кінці характерні бальзамічні ароматні ноти.",
    info: "",
    imgUrl: "/catalog/velutato.jpg",
    link: "",
  },
] as unknown as ProductCardInterface[];
